<template>
  <!--    TripRequest Form-->
  <validation-observer
      ref="tripRequestForm"
      v-slot="{invalid}"
  >
    <lenon-modal
        :title="`${updateTripRequestMode?'Update':'Create'} Trip Request`"
        :show="tripRequestModalOpened"
        :show-overlay="deletingTripRequest || loadingStudent"
        size="md"
        @onClose="closeTripRequestModal()"
    >
      <error-display :error="error" />
      <b-row class="align-items-start">
        <div class="col-7">
          <lenon-select
              v-model="selectedTripRequestId"
              :options="tripRequests"
              label-name="full_name"
              value-name="id"
              placeholder="Trip Requests"
              @input="populateTripRequestsForm"
          />
        </div>
        <div class="col-2">
          <lenon-dropdown
              icon="TrashIcon"
          >
            <b-dropdown-item @click="deleteTripRequest()">
              Yes
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item>
              No
            </b-dropdown-item>
          </lenon-dropdown>
        </div>
        <div class="col-2">
          <lenon-button
              variant="flat-danger"
              icon="XIcon"
              label=""
              tool-tip-text="Clear Form"
              @onClick="resetTripRequestForm()"
          />
        </div>
      </b-row>
      <lenon-input
          name="student"
          placeholder="Enter Name(Last + First Name) or ID then press Enter"
          label="Student"
          suffix-icon="SearchIcon"
          v-model="searchTerm"
          @onEnterPressed="findStudent"
          @onSuffixIconClick="findStudent"
      />
      <div class="mt-1" v-if="studentDetails.name">
        <div class="mt-2 d-flex flex-column justify-content-center align-items-center">
          <lenon-profile-photo
              :photo="studentDetails.photo"
              size="70"
          />
          <strong>{{studentDetails.name}}</strong>
        </div>
      </div>
      <lenon-select
          v-model="tripRequest.pickup_point_id"
          name="pickup_point"
          placeholder="Select Pickup Point"
          rules="required"
          label="Pickup Point"
          label-name="label"
          value-name="id"
          :options="pickupPoints"
      />
      <lenon-select
          v-model="tripRequest.payment_basis"
          name="payment_basis"
          placeholder="Select Payment Basis"
          rules="required"
          label="Payment Basis"
          label-name="label"
          value-name="id"
          :options="paymentBasis"
      />
      <lenon-select
          v-model="tripRequest.trip_option"
          name="trip_option"
          placeholder="Select Trip Option"
          rules="required"
          label="Trip Option"
          label-name="label"
          value-name="id"
          :options="tripOptions"
      />
      <lenon-select
          v-model="tripRequest.status"
          name="status"
          placeholder="Select Status"
          rules="required"
          label="Request Status"
          label-name="label"
          value-name="id"
          :options="statuses"
      />
      <template slot="modal-actions">
        <b-row class="float-right">
          <lenon-button
              variant="flat-danger"
              icon="XIcon"
              class="mr-1"
              label="Cancel"
              @onClick="closeTripRequestModal()"
          />
          <lenon-button
              :label="updateTripRequestMode?'Update':'Create'"
              :disabled="invalid"
              :loading="tripRequestLoading"
              loading-text="Loading..."
              @onClick="updateTripRequestMode?updateTripRequest():createTripRequest()"
          />
        </b-row>
      </template>
    </lenon-modal>
  </validation-observer>
</template>

<script>

import {
  BRow, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import LenonModal from '@/lenon/components/LenonModal.vue'
import LenonInput from '@/lenon/components/LenonInput.vue'
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import confirm from '@/lenon/mixins/confirm'
import LenonSelect from '@/lenon/components/LenonSelect.vue'
import {
  CREATE_EXPENSE_M,
  DELETE_EXPENSE_M,
  UPDATE_EXPENSE_M,
} from '@/graphql/mutations'
import LenonDropdown from '@/lenon/components/LenonDropdown.vue'
import LenonDatePicker from '@/lenon/components/LenonDatePicker.vue'
import logData from '@/libs/log'
import ErrorDisplay from '@/lenon/components/ErrorDisplay.vue'
import LenonTextArea from '@/lenon/components/LenonTextArea.vue'
import LenonProfilePhoto from '@/lenon/components/LenonProfilePhoto'

export default {
  name: 'TripRequestSetup',
  components: {
    LenonProfilePhoto,
    LenonTextArea,
    ErrorDisplay,
    LenonDatePicker,
    LenonDropdown,
    LenonSelect,
    LenonButton,
    LenonInput,
    LenonModal,
    ValidationObserver,
    BRow,
    BDropdownItem,
    BDropdownDivider,
  },
  mixins: [showToast, confirm],
  props: {
    modalOpened: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      error: {},
      deletingTripRequest: false,
      tripRequestModalOpened: false,
      updateTripRequestMode: false,
      tripRequestLoading: false,
      loadingStudent: false,
      selectedTripRequestId: null,
      searchTerm: null,
      studentDetails:{
        name: null,
        photo: null,
      },
      tripRequest: {
        id: null,
        student_id: null,
        pickup_point_id: null,
        payment_basis: null,
        trip_option: null,
        status: null,
      },
      paymentBasis:[{id:"daily",label:"Daily"},{id:"weekly",label:"Weekly"},{id:"monthly",label:"Monthly"}],
      tripOptions:[{id:"busin",label:"Bus In"},{id:"busout",label:"Bus Out"},{id:"both",label:"Both"}],
      statuses:[{id:"approved",label:"Approved"},{id:"pending",label:"Pending"},{id:"rejected",label:"Rejected"}],
    }
  },
  computed: {
    tripRequests() {
      return this.$store.getters['tripRequest/tripRequests']
    },
    pickupPoints() {
      return this.$store.getters['pickupPoint/pickupPoints']
    },
    selectedTripRequest() {
      return this
          .tripRequests.find(g => +g.id === +this.selectedTripRequestId)
    },
  },
  watch: {
    modalOpened(opened) {
      this.tripRequestModalOpened = opened
    },
    selectedTripRequestId(id) {
      if (!id) {
        this.resetTripRequestForm()
      }
    },
    id(id) {
      if (id) {
        this.selectedTripRequestId = id
      }
    },
  },
  methods: {
    findStudent(){
      if (!this.searchTerm) {
        return
      }
      this.loadingStudent = true
      this.$http.get(`transportation/find-student?search=${this.searchTerm}`).then(res => {
        this.studentDetails = res.data.display_data
        this.tripRequest.student_id = res.data.id
        this.searchTerm = null
      })
          .catch(err => {
            this.error = err
            logData(err)
            this.showError('Failed to retrieve student')
          }).finally(()=>{
        this.loadingStudent = false
      })
    },
    resetTripRequestForm() {
      this.updateTripRequestMode = false
      this.tripRequest = {
        id: null,
        student_id: null,
        pickup_point_id: null,
        payment_basis: null,
        trip_option: null,
        status: null,
      }
      this.selectedTripRequestId = null
      this.studentDetails.name = null
      this.studentDetails.photo = null
      this.$refs.tripRequestForm.reset()
    },
    populateTripRequestsForm(ef) {
      if (this.selectedTripRequest) {
        this.updateTripRequestMode = true
        this.tripRequest = {
          ...this.selectedTripRequest,
        }
        this.studentDetails.name = this.selectedTripRequest.full_name
        this.studentDetails.photo = this.selectedTripRequest.photo
      } else {
        this.resetTripRequestForm()
      }
    },
    closeTripRequestModal() {
      this.tripRequestModalOpened = false
      this.$emit('modalClosed')
    },
    updateTripRequest() {
      this.error = {}
      if (!this.tripRequest.id) {
        return
      }
      this.tripRequestLoading = true
      this.$http.put(`transportation/trip-requests/${this.tripRequest.id}`,this.tripRequest).then(res => {
        this.tripRequestLoading = false
        this.showSuccess('Updated tripRequest successfully')
        this.$store.commit('tripRequest/updateTripRequest', res.data)
      })
          .catch(err => {
            this.error = err
            logData(err)
            this.showError('Failed to update trip request')
            this.tripRequestLoading = false
          })
    },
    createTripRequest() {
      this.error = {}
      this.tripRequestLoading = true
      this.$http.post("transportation/trip-requests",this.tripRequest).then(res => {
        this.showSuccess('Created trip request successfully')
        this.tripRequestLoading = false
        this.$store.commit('tripRequest/addTripRequest', res.data)
        this.resetTripRequestForm()
      })
          .catch(err => {
            this.error = err
            logData(err)
            this.showError('Failed to create trip request')
            this.tripRequestLoading = false
          })
    },
    deleteTripRequest() {
      if (!this.selectedTripRequestId) {
        this.showInfo('Please select a trip request')
        return
      }
      this.deletingTripRequest = true
      this.$http.delete(`transportation/trip-requests/${this.selectedTripRequestId}`).then(() => {
        this.deletingTripRequest = false
        this.showSuccess('Deleted trip request successfully')
        this.$store.commit('tripRequest/removeTripRequest', this.selectedTripRequestId)
        this.selectedTripRequestId = null
        this.resetTripRequestForm()
      })
          .catch(err => {
            logData(err)
            this.showError('Failed to delete trip request')
            this.deletingTripRequest = false
          })
    },
  },
}
</script>
