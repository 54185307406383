<template>
  <div class="requests">
    <lenon-list-page
        title="Requests"
        :columns="columns"
        :rows="requests"
        :table-loading="tableLoading"
        :show-create="false"
        :show-refresh="false"
        :show-search="true"
        :show-details="true"
        show-profile-photo
        search-placeholder="Search"
    >
      <template slot="table-header">
        <div
            v-responsive="$store.getters['app/smallScreen']"
            class="col-12"
        >
          <b-collapse
              id="request"
          >
            <div class="row">
              <div class="col-md-6">
              <lenon-select v-model="selectedClassId" placeholder="Select Class" label-name="name" value-name="id" :options="classes"/>
            </div>
              <div class="col-md-6">
                <lenon-button
                    icon="SettingsIcon"
                    label="Requests"
                    tool-tip-text="Manage Requests(CRUD)"
                    class="mb-1"
                    @onClick="showRequestsModal()"
                />
              </div>
            </div>
          </b-collapse>
        </div>
      </template>
      <template slot="right-extra-header-actions">
        <div
            v-responsive="$store.getters['app/largeScreen']"
            class="d-flex align-self-center align-items-start"
        >
          <lenon-select v-model="selectedClassId" class="mr-1" placeholder="Select Class" label-name="name" value-name="id" :options="classes"/>
          <lenon-button
              icon="SettingsIcon"
              label="Requests"
              tool-tip-text="Manage Requests(CRUD)"
              class=""
              @onClick="showRequestsModal()"
          />
        </div>
        <div
            v-responsive="$store.getters['app/smallScreen']"
            class="d-flex align-self-center align-items-center"
        >
          <lenon-button
              v-b-toggle.request
              icon-only
              icon="MoreVerticalIcon"
              variant="flat-primary"
          />
        </div>
      </template>
      <template #status="{row}">
        <b-badge :variant="getColor(row.item.status)">
          {{ row.item.status.toString().toUpperCase() }}
        </b-badge>
      </template>
      <template #pickup_point="{row}">
        <b-badge variant="light-secondary">
          {{ row.item.pickup_point.toString().toUpperCase() }}
        </b-badge>
      </template>
      <template #payment_basis="{row}">
        <b-badge variant="light-secondary">
          {{ row.item.payment_basis.toString().toUpperCase() }}
        </b-badge>
      </template>
      <template #trip_option="{row}">
        <b-badge variant="light-secondary">
          {{ row.item.trip_option.toString().toUpperCase() }}
        </b-badge>
      </template>
    </lenon-list-page>

    <requests-setup
        :modal-opened="requestsModalOpened"
        @modalClosed="closeModal('requests')"
    />
  </div>
</template>

<script>
import LenonButton from '@/lenon/components/LenonButton.vue'
import showToast from '@/lenon/mixins/showToast'
import LenonListPage from '@/lenon/components/LenonListPage.vue'
import RequestsSetup from '@/views/transportation/trips/requests/RequestSetup.vue'
import LenonDropdown from '@/lenon/components/LenonDropdown'
import LenonDropdownItem from '@/lenon/components/LenonDropdownItem'
import { VBToggle, BCollapse, BBadge, BCard } from 'bootstrap-vue'
import LenonSelect from '@/lenon/components/LenonSelect'

export default {
  name: 'Requests',
  components: {
    LenonSelect,
    LenonDropdownItem,
    LenonDropdown,
    LenonListPage,
    RequestsSetup,
    LenonButton,
    BBadge,
    BCollapse,
    BCard
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [showToast],
  data() {
    return {
      requestsModalOpened: false,
      tableLoading: false,
      selectedClassId: null,
      columns: [
        {
          key: 'index',
          label: '#',
        },
        {
          key: 'photo',
          label: 'Photo',
        },
        {
          key: 'full_name',
          label: 'Student Name',
        },
        {
          key: 'pickup_point',
          label: `Pickup Point`,
        },
        {
          key: 'payment_basis',
          label: `Payment Basis`,
        },
        {
          key: 'trip_option',
          label: 'Trip Option',
        },
        {
          key: 'status',
          label: 'Status',
        },
        // {
        //   key: 'actions',
        //   label: 'Action',
        // },
      ],
    }
  },
  computed: {
    requests() {
      return this.$store.getters['tripRequest/tripRequests']
    },
    classes() {
      return this.$store.getters['termsClasses/classes']
    },
    vehicles() {
      return this.$store.getters['vehicle/vehicles']
    },
    pickupPoints() {
      return this.$store.getters['pickupPoint/pickupPoints']
    },
    users() {
      return this.$store.getters['accountUsers/users']
    },
  },
  watch: {
    selectedClassId(val){
      if(val){
        this.fetchRequests()
      }else{
        this.$store.commit("tripRequest/setTripRequests",[])
      }
      this.$store.commit("tripRequest/setSelectedClassId",val)
    }
  },
  mounted() {
    this.selectedClassId = this.$store.getters["tripRequest/selectedClassId"]
  },
  methods: {
    getColor(status){
      if(status==='approved'){
        return 'light-success'
      }
      if(status==='pending'){
        return 'light-warning'
      }
      if(status==='rejected'){
        return 'light-danger'
      }
    },
    getLocations(ids){
      return this.pickupPoints.filter((p)=>ids.includes(+p.id)).map((p)=>p.label)
    },
    getVehicleDetails(id){
      return this.vehicles.find((u) => +u.id===+id).label
    },
    getUsersDetails(ids) {
      return this.users.filter((u) => ids.includes(+u.id))
          .map((u) => u.full_name)
          .join(', ')
    },
    closeModal(type) {
      // eslint-disable-next-line default-case
      switch (type) {
        case 'requests':
          this.requestsModalOpened = false
          break
      }
    },
    showRequestsModal() {
      if(this.selectedClassId){
        this.requestsModalOpened = true
      }else{
        this.showInfo("Please select a class")
      }
    },

    fetchRequests() {
      this.tableLoading = true
      this.$http.get(`transportation/trip-requests?class_id=${this.selectedClassId}`)
          .then(res => {
            this.$store.commit('tripRequest/setTripRequests', res.data)
          })
          .catch(err => {
            this.showError('Failed to load trip requests')
          })
          .finally(() => {
            this.tableLoading = false
          })
    },
  },
}
</script>
